@import "src/variables";

$waring: darken($color-warning, 12%);
$success: darken($color-success, 15%);

.evaluation-list {
  @include page;
}

.evaluation-card {
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 5px 2px 10px lighten($color-gray, 50%);
  display: flex;

  .score {
    background-color: $waring;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    font-size: 1.3rem;
    width: 3rem;
    color: white;
    line-height: 5rem;
    text-align: center;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

    &.complete {
      background-color: $success;
    }
  }

  > .content {
    text-align: left;
    padding: 20px 20px 55px 20px;
    position: relative;
    width: 100%;

    .name {
      font-weight: 500;
      font-size: 1.1rem;
      margin: 0;
      text-align: left;
      list-style-type: circle;
    }

    .link {
      color: $color-info;
      padding-top: 10px;
    }

    .date {
      padding: 10px 0;
      border-top: 1px lightgray dashed;
      border-bottom: 1px lightgray dashed;
      margin: 12px 0;

      > p {
         margin: 0;
        font-size: 0.8rem;
      }
    }

    .status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;
      width: 90%;
      position: absolute;
      bottom: 15px;

      .state {
        color: $waring;
        font-size: 1.2rem;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

        &.complete {
          color: $success;
        }
      }

      .cta {
        background-color: $secondary;
        outline: none;
        border: none;
        color: white;
        padding: 10px 20px;
        border-radius: 50px;
        cursor: pointer;
      }
    }
  }
}