@import "src/index";

.input-wrapper {
  width: 100%;
  margin-bottom: 0.4rem;

  > .label {
    text-align: left;
    margin: 0 0 0 5px;
    font-weight: 500;
  }

  > .input {
    border: lightgray 1px solid;
    font-size: 1rem;
    border-radius: 5px;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 300;
    width: 100%;

    &:focus {
      border: 1px lighten($primary, 10%) solid;
      -moz-outline-radius: 10px;
    }
  }

  > .error {
    text-align: left;
    padding-left: 5px;
  }
}