@import "src/variables";

.list-item-check {
  list-style-type: circle;

  &.evaluated {
    list-style-type: none;
  }

  > .wrapper {
    margin-right: 10px;
    display: inline-block;
    position: relative;
    top: 7px;

    > .pass {
      color: $color-success;
    }

    > .fails {
      color: $color-error;
    }
  }

  > .details-link {
    color: $color-info;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
}

.detail-link {
  display: block;
  color: $primary;
  cursor: pointer;
  margin-bottom: 10px;

  &:visited {
    color: $purple;
  }
}

.details-close-icon {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  color: $color-text-dark;
}