@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');

$font-family: 'Poppins', sans-serif;

$color-primary: #f0e3ca;
$color-secondary: #ff8303;
$color-tertiary: #a35709;
$color-bloom: #2b2e4a;
$color-dark: #1b1a17;
$color-info: #51c4d3;
$color-warning: #ffcc29;
$color-success: #9ede73;
$color-error: #e84545;

$color-gray: #c2c2c2;

$color-text-light: #edffec;
$color-text-dark: #40394a;

$dark: #111b27;
$medium-dark: #1a2738;
$blue-green: #00a9a3;
$purple: #503bc8;
$light-purple: #614de2;

// theming
$primary: $blue-green;
$secondary: $medium-dark;
$tertiary: $dark;
$light: white;

@mixin page {
  padding: 0 2vw 30px 2vw;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}