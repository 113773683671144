@import "src/variables";

.input-option {
  input {
    margin: 10px 0;
    max-width: 400px;

    &:focus {
      border: 1px $color-info solid;
    }
  }
}