@import "src/variables";

$br-radius: 20px;

.evaluation {
  display: flex;
  max-height: 100vh;
  overflow-y: auto;

  > .content {
    width: 100%;
  }
}