@import "src/variables";

.item-template {
  @include page;
  position: relative;
  height: 100vh;

  .blocking-loading {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    transition: opacity 0.4s linear;
    backdrop-filter: blur(2px);
    display: none;
    flex-direction: column;

    > * {
      color: $color-bloom;
    }

    &.visible {
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
    }
  }

  > header {
    position: relative;

    > .score {
      position: absolute;
      right: 0;
      top: -25px;
      background-color: $secondary;
      font-size: 2rem;
      padding: 13px 0;
      color: $light;
      font-weight: 600;
      border-radius: 50%;
      line-height: 26px;
      width: 100px;
      height: 100px;
      text-align: center;

      > span {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        text-align: center;
        color: $primary;
      }
    }
  }

  form {
    > .cta {
      background-color: $tertiary;
      color: white;
      outline: none;
      border: none;
      font-size: 1.2rem;
      padding: 10px 20px;
      border-radius: 50px;
      cursor: pointer;
      margin: 10px 20px;
      text-decoration: none;

      &[type=submit], &.summary {
        background-color: $primary;
      }
    }
  }
}