@import "src/index";

$br-radius: 10px;

.option {
  background-color: white;
  margin-bottom: 20px;
  border-radius: $br-radius;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border: rgba(0, 0, 0, 0.15) 1px solid;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .wrapper {
    >.header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      >.tag {
        background-color: lighten($primary, 4%);
        padding: 5px 10px;
        border-radius: 14px;
        font-weight: 600;
        user-select: none;
        margin-left: 10px;
      }
    }

    > .content {
      padding: 0 30px;
      text-align: left;
    }
  }

  >.score-tag {
    background-color: rgba($tertiary, 0.061);
    min-width: 50px;
    border-bottom-left-radius: $br-radius;
    border-bottom-right-radius: $br-radius;
    text-align: center;
    padding: 10px 20px;
    color: white;
    font-weight: 600;
    font-size: 0.82rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    div {
      text-align: center;
      display: flex;
      align-items: center;

      p {
        float: left;
      }

      span {
        float: right;
      }
    }

    p {
      margin: 0;

      &:last-child {
        font-weight: bold;
        font-size: 1rem;
        background-color: rgba(white, 0.5);
        width: 50px;
        padding: 2px 0;
        border-radius: 5px;
      }
    }
  }
}