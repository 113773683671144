@import "src/variables";

.home {
  max-width: 100vw;
  height: 100vh;
  padding: 0;
  display: flex;

  > .content {
    width: 100%;

    > .form {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  &__submit {
    cursor: pointer;
    background-color: $primary;
    color: $color-text-light;
    border: none;
    outline: none;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      transform: scale(0.99);
    }
  }
}