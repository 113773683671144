@import "src/variables";

.menu {
  max-width: 170px;
  height: 100vh;
  box-shadow: 3px 0 30px rgba(0, 0, 0, 0.4);
  padding: 10px 0 30px 0;
  overflow-y: auto;
  z-index: 10;

  &.dark {
    background-color: $secondary;

    > .title {
      color: $light;
    }
  }

  &.light {
    background-color: $light;
    box-shadow: 3px 0 20px rgba(0, 0, 0, 0.25);

    > .title {
      color: $secondary;
      font-weight: 600;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  > .title {
    color: $color-text-light;
    margin: 15px 0;
    font-weight: 400;
    font-size: 0.9rem;
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.menu-item {
  cursor: pointer;
  padding: 10px 0;

  &.dark {
    &:hover {
      background-color: lighten($tertiary, 7%);
    }

    &.selected {
      background-color: $tertiary;
    }
  }

  &.light {
    &:hover {
      background-color: lighten($color-text-dark, 60%);
    }

    &.selected {
      background-color: lighten($color-text-dark, 65%);
    }
  }

  > .link {
    text-decoration: none;
    > * {
      color: $primary;
    }
  }
}

