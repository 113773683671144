@import "src/variables";

label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  overflow: hidden;
  margin-right: 15px;
  position: relative;

  &.disabled {
    cursor: not-allowed;

    input {
      &:checked + span {
        background-color: mix(#fff, $color-info, 70%);

        &:before {
          box-shadow: inset 0 0 0 0.4375em $color-info;
        }
      }
    }

    span {
      &:hover {
        background-color: transparent;
      }
    }
  }

  input {
    position: absolute;
    left: -9999px;

    &:checked + span {
      background-color: mix(#fff, $color-info, 70%);

      &:before {
        box-shadow: inset 0 0 0 0.4375em $color-info;
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;

    &:hover {
      background-color: mix(#fff, $color-info, 70%);
    }

    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em $color-info;
    }
  }
}